import React, { Component } from "react"

class FAQ extends Component {
  componentDidMount() {
    if (typeof window !== `undefined`)
      window.location = `https://learnandearnsupport.atlassian.net/servicedesk/customer/portals`
  }
  render() {
    return null
  }
}

export default FAQ
